export const navigation = new hcOffcanvasNav('#main-nav', {
	disableAt: 1200,
	customToggle: '.toggle',
	navTitle: false,
	levelSpacing: 0,
	levelTitles: false,
	levelTitleAsBack: false,
	bodyInsert: 'append',
	insertClose: false,
	width: 320,
});

export function bodyOverlay() {
	$('li.menu-item-has-children').on('mouseover', function (e) {
		$('body').addClass('overlay');
	});

	$('li.menu-item-has-children').on('mouseout', function (e) {
		$('body').removeClass('overlay');
	});
}

export function navigationFunctions(navigation) {
	navigation.on('toggle', (e, settings) => {
		$('.hamburger.main').toggleClass('open');
	});
}
