import {
  lazyloadVideoElement,
  lazyloadiFrameElement,
  insPanelCollapse
} from "./utilities/lazyload";
import { navigation, bodyOverlay, navigationFunctions } from "./components/nav";
import { searchFilters } from "./utilities/searchFilter";
import {
  initSwiper,
  productThumbs,
  productGallery,
} from "./components/sliders";
import ClipboardJS from "clipboard";
// import initDownloadsPage from './pages/downloads-page';
// import { initPostFilters } from './components/filters';
// import { initDericSearch } from "./components/resourcesSearch";

$(function () {
  // Utilities
  lazyloadiFrameElement();
  lazyloadVideoElement();
  searchFilters();

  // Navigation
  navigationFunctions(navigation);
  bodyOverlay();

  //Sliders
  initSwiper();

  // Filters
  initPostFilters();

  //initDownloadsPage();

  //initDericSearch();

  insPanelCollapse();
  
});

$("#copyLink").tooltip({
  trigger: "click",
  placement: "bottom",
});

function setTooltip(message) {
  $("#copyLink")
    .tooltip("hide")
    .attr("data-bs-original-title", message)
    .tooltip("show");
}

function hideTooltip() {
  setTimeout(function () {
    $("#copyLink").tooltip("hide");
  }, 1000);
}

var clipboard = new ClipboardJS("#copyLink");

clipboard.on("success", (e) => {
  setTooltip("Copied!");
  hideTooltip();
});

clipboard.on("error", (e) => {
  setTooltip("Failed!");
  hideTooltip();
});

function pressEnter() {
  const event = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      which: 13,
      keyCode: 13,
  });

  document.getElementById('fakeSubmit').dispatchEvent(event);
}

document.getElementById('fakeSubmit').addEventListener('click', pressEnter());