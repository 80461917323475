export function lazyloadVideoElement() {
	var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

	if ('IntersectionObserver' in window) {
		var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove('lazy');
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
}

export function lazyloadiFrameElement() {
	var videoWrapper = document.querySelectorAll('.videoWrapper');

	for (var i = 0; i < videoWrapper.length; i++) {
		videoWrapper[i].addEventListener('click', function () {
			var link = this.classList.contains('vimeo') ? 'https://player.vimeo.com/video/' : 'https://www.youtube.com/embed/';
			var iframe = document.createElement('iframe');

			iframe.setAttribute('frameborder', '0');
			iframe.setAttribute('allowfullscreen', '');
			iframe.setAttribute('src', link + this.dataset.embed + '?rel=0&showinfo=0&autoplay=1');

			this.innerHTML = '';
			this.appendChild(iframe);
		});
	}
}

export function insPanelCollapse() {
	$(".accordion-collapse").on("shown.bs.collapse", function (e) {
	  var $panel = $(this).closest(".accordion-item");
	  var $top = $panel.offset().top - 100;
	  $("html,body").animate(
		{
		  scrollTop: $top,
		},
		500
	  );
	});
  }