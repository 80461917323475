// Import Swiper and modules
import Swiper, { Navigation, Pagination, EffectFade, Thumbs, Autoplay } from 'swiper/core';

// Install modules
Swiper.use([Navigation, Pagination, EffectFade, Thumbs, Autoplay]);

export function initSwiper() {
	const initSwiper = document.querySelectorAll('[data-swiper]');
	initSwiper.forEach((element) => {
		let params = JSON.parse(element.dataset.swiper);
		new Swiper(element, params);
	});
}

export const productThumbs = new Swiper('#productThumbs', {
	slidesPerView: 2,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	freeMode: true,
	loopedSlides: 0, //looped slides should be the same
	breakpoints: {
		576: {
			slidesPerView: 3,
		},
	},
});

export const productGallery = new Swiper('#productGallery', {
	loopedSlides: 0, //looped slides should be the same
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	thumbs: {
		swiper: productThumbs.slides.length <= 0 ? false : productThumbs,
	},
});
